import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import T_ABOUT_QUESTIONS from "../../components/t_about_questions"

const AboutMoisturePage = () => (
  <Layout sectionClasses={"t-about-detail"} dataNamespace={"about_moisturizer"}>
    <SEO title="About Moisture" />
    <div className="t-about-moisturizer">
      <div className="t-about-detail__kv">
        <div className="t-about-detail__kv__ttl">
          <h1>MOISTURIZER</h1>
        </div>
        <picture className="t-about-detail__kv__img">
          <source media="(max-width:959px)" srcSet={"/images/about/moisturizer-kv-sp.jpg"} />
          <img  src={"/images/about/moisturizer-kv-pc.jpg"} alt="" />
        </picture>
      </div>

      <div className="t-about-detail__product">
        <div className="t-about-detail__product__inner">
          <div className="t-about-detail__product__ttl">
            <p>HOTARU<br />PERSONALIZED<br />MOISTURIZER</p>
          </div>
          <div className="t-about-detail__product__img">
            <img src={"/images/about/moisturizer-bottle.png"} alt="" />
          </div>
          <div className="t-about-detail__product__txt">
            <p className="t-about-detail__product__txt__ttl">11万通りの肌診断結果からあなたに合わせる処方で<br />プレミアムバランス肌</p>
            <p className="t-about-detail__product__txt__desc">美しい肌は、水分量と皮脂量の綿密なバランスが保たれています。<br />しかし肌は常に外気やストレスなど複雑な問題にさらされているもの。<br />あなたの綿密なバランスを守り続けるには、あなたの肌状態に合わせて水分や油分を補い保ち、肌を健やかな状態に導くことが大切です。</p>
          </div>
        </div>
      </div>

      <div className="t-about-detail__pt1">
        <div className="t-about__pt__ttl"><p>POINT</p></div>
        <div className="t-about-detail__pt1__txt">
          <p className="t-about-detail__pt1__txt__ttl">スキンケアストレスから解放され、あなた色に輝く肌へ</p>
          <p className="t-about-detail__pt1__txt__desc">複雑な肌のバランスを整えるため、あらゆるアプローチでお肌に働きかけるHOTARU PERSONALIZED MOISTURIZER。あなたの肌状態に合わせて水分や油分を補い保ちながら、肌診断結果から配合されたエッセンスたちが肌を健やかに導き、ツヤが輝く肌へ</p>
        </div>
        <div className="t-about-detail__pt1__img">
          <img src={"/images/about/moisturizer-point1.jpg"} alt="" />
        </div>
        <ul>
          <li><span>肌を保護し、乾燥を防ぐ。</span><br />肌にダメージを与えるあらゆる外部環境から肌を保護し、乾燥を防ぐ。</li>
          <li><span>肌の水分、油分を補い、保つ。</span><br />肌の水分・油分の状態を診断し、あなたに合わせて肌の水分・油分を補い保つ。</li>
          <li><span>肌を健やかに導く。</span><br />肌荒れや乾燥などの肌悩みを解析した結果を元に成分を調合。</li>
        </ul>
      </div>

      <div className="t-about-detail__pt2">
        <img className="t-about__bgImg1" src={"/images/about/color1.png"} alt="" />
        <img className="t-about__bgImg2" src={"/images/about/color2.png"} alt="" />
        <div className="t-about__pt__ttl"><p>POINT</p></div>
        <div className="t-about-detail__inner">
          <div className="t-about-detail__inner__img">
            <img src={"/images/about/moisturizer-wolfberry.jpg"} alt="" />
          </div>
          <div className="t-about-detail__inner__txt">
            <p className="t-about-detail__inner__txt__ttl">歴史の美女たちも愛した“不老長寿の妙薬”</p>
            <p className="t-about-detail__inner__txt__desc">老化防止に効果があり、クレオパトラや楊貴妃も愛したと言われるクコの実。HOTARU PERSONALIZEDでは、さらに希少な国産・無農薬栽培枸杞の実を使用してクコ果実エキス発酵液を抽出。<br />食べても美容に良いと言われる“クコの実”が、あなたの肌を健やかに整えます。</p>
          </div>
        </div>
      </div>

      <div className="t-about-detail__pt3">
        <div className="t-about__pt__ttl"><p>POINT</p></div>
        <div className="t-about-detail__inner">
          <div className="t-about-detail__inner__img">
            <img src={"/images/about/moisturizer-essence.jpg"} alt="" />
          </div>
          <div className="t-about-detail__inner__txt">
            <p className="t-about-detail__inner__txt__ttl">あなたの肌トラブルに対処する<br />エッセンスチューブ</p>
            <p className="t-about-detail__inner__txt__desc">HOTARU PERSONALIZED MOISTURIZERでは、肌状態に合わせて皮脂量・水分量のバランスを整えながら、同時に肌トラブルにもアプローチ。<br />オンライン肌診断で肌の状態や肌トラブルを回答すると、11万通りの肌診断結果から、ぴったりのエッセンスをセレクトし、モイスチャライザーへ調合します。</p>
          </div>
        </div>
      </div>

      <div className="t-about-detail__essence">
        <img className="t-about__bgImg1" src={"/images/about/color1.png"} alt="" />
        <img className="t-about__bgImg2" src={"/images/about/color2.png"} alt="" />
        <p className="t-about-detail__essence__ttl">ESSENCE</p>
        <div className="t-about-detail__essence__outer">
          <div className="t-about-detail__essence__outer__left js-smScrollFire js-smScrollFire--fadeUp">
            <div className="t-about-detail__essence__inner">
              <img className="t-about-detail__essence__img" src={"/images/common/tubes/tube-01.png"} alt="" />
              <ul className="t-about-detail__essence__ingridients">
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-02.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">エチルヘキサン酸セチル</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">サラッとした仕上がり</p>
                  </div>
                </li>
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-03.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">スクワラン</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">肌への優れた浸透性（角質層まで）</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="t-about-detail__essence__inner">
              <img className="t-about-detail__essence__img" src={"/images/common/tubes/tube-06.png"} alt="" />
              <ul className="t-about-detail__essence__ingridients">
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-05.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">グリコシルトレハロース</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">肌荒れを防ぐ</p>
                  </div>
                </li>
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-04.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">グリセリン</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">肌をうるおって守る</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="t-about-detail__essence__inner">
              <img className="t-about-detail__essence__img" src={"/images/common/tubes/tube-16.png"} alt="" />
              <ul className="t-about-detail__essence__ingridients">
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-10.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">ホホバ種子油</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">なめらかな使用感</p>
                  </div>
                </li>
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-11.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">ミリスチン酸オクチルドデシル</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">ベタつかずにしっとり潤い効果</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="t-about-detail__essence__line"></div>
          <div className="t-about-detail__essence__outer__right js-smScrollFire js-smScrollFire--fadeUp">
            <div className="t-about-detail__essence__inner">
              <img className="t-about-detail__essence__img" src={"/images/common/tubes/tube-52.png"} alt="" />
              <ul className="t-about-detail__essence__ingridients">
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-12.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">イノシトール</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">皮脂バランスを補う</p>
                  </div>
                </li>
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-14.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">レモン果実エキス</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">肌リズムをサポート</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="t-about-detail__essence__inner">
              <img className="t-about-detail__essence__img" src={"/images/common/tubes/tube-67.png"} alt="" />
              <ul className="t-about-detail__essence__ingridients">
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-18.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">キク花エキス</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">ツヤのあるクリアな肌作りをサポート</p>
                  </div>
                </li>
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-29.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">レモングラス葉/茎エキス</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">肌リズムをサポート</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="t-about-detail__essence__inner">
              <img className="t-about-detail__essence__img" src={"/images/common/tubes/tube-73.png"} alt="" />
              <ul className="t-about-detail__essence__ingridients">
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-28.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">シイクワシャー果皮エキス</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">肌のハリを与える</p>
                  </div>
                </li>
                <li>
                  <img className="t-about-detail__essence__ingridients__img" src={"/images/common/ingridients/ingridient-30.jpg"} alt="" />
                  <div className="t-about-detail__essence__ingridients__txt">
                    <p className="t-about-detail__essence__ingridients__txt__ttl">オトギリソウ花/葉/茎エキス</p>
                    <p className="t-about-detail__essence__ingridients__txt__desc">美容成分を保護</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p className="t-about-detail__essence__more">AND MORE...</p>
      </div>
      <Link className="t-about-detail__label" to="/about/lotion/">
        <div className="t-about-detail__label__inner">
          <div className="t-about-detail__label__ttl">LOTION</div>
          <div className="t-about-detail__label__more">VIEW MORE</div>
        </div>
      </Link>
      <T_ABOUT_QUESTIONS />
      <Link class="p-pageBackBtn" to="/">
        <div class="c-grid">
          <div class="c-grid__row">
            <div class="c-grid__col">
              <span class="p-pageBackBtn__inner">BACK TO TOP</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  </Layout>
)

export default AboutMoisturePage
